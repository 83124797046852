let config
if (process.env.NODE_ENV == 'development') {
  config={
    url: 'https://id.ao-nk.ru/oauth',

    params: {
      // ид клиента
      clientId: 'a036ee68-ceb5-4bd4-9d27-72e62ba488ad',
      // требуемые разрешения (АО НК может отдать меньше разрешений, но не больше чем было указно в настроках клиента)
      scopes: [
        'name',
        'surname',
        'patronymic',
        'email',
        'phone'
      ],
    }
  }
} else {
  config={
    url: 'https://id.ao-nk.ru/oauth',

    params: {
      // ид клиента
      clientId: 'a036ee68-ceb5-4bd4-9d27-72e62ba488ad',
      // требуемые разрешения (АО НК может отдать меньше разрешений, но не больше чем было указно в настроках клиента)
      scopes: [
        'name',
        'surname',
        'patronymic',
        'email',
        'phone'],
    }
  }
}

module.exports = config
