import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Index.vue')
  },
  {
    path: '/form',
    name: 'form',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/FormView.vue')
  },
  {
    path: '/oldform',
    name: 'oldform',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/OldFormView.vue')
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CheckView.vue'),
    children: [{
      path: '/admin',
      component: () => import(/* webpackChunkName: "about" */ '../components/Admin/Filled/FormList.vue')
    }, {
      path: '/admin/form/:id',
      component: () => import(/* webpackChunkName: "about" */ '../components/Admin/Form.vue'),
      children: [{
        path: '/admin/form/:id',
        component: () => import(/* webpackChunkName: "about" */ '../components/Admin/Form.vue')
      }, {
        path: '/admin/form/:id/constructor',
        component: () => import(/* webpackChunkName: "about" */ '../components/Admin/Constructor/FormList.vue')
      }]
    }]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
