

import { createApp } from 'vue'
import App from './App.vue'

import JsonExcel from "vue-json-excel3";

import { registerPlugins } from '@/plugins'

// import '@/assets/scss/variables.scss'

//И зарегистрируем их в нашем приложении

const app = createApp(App);

app.component("downloadExcel", JsonExcel);

registerPlugins(app);
app.mount('#app')


