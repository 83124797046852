// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
          colors: {
              primary: '#2FBFD7',
              secondary: '#EBF7F9',
              background: '#EBF7F9',
              surface: '#FFFFFF'
          }
        }
    }
  }
})
