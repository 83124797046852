/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import { loadFonts }         from './webfontloader'
import vuetify               from './vuetify'
import pinia                 from '../store'
import router                from '../router'
import axios                 from 'axios';
import {createAuth}          from '@websanova/vue-auth';
import driverAuthBearer      from '@websanova/vue-auth/dist/drivers/auth/bearer.esm.js';
import driverHttpAxios       from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js';
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js';
import VueAxios              from "vue-axios";

import aonkId from '@/plugins/aonkId.js';

if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = 'http://localhost:8365'
    axios.defaults.backUrl = 'http://localhost:8081'
  }else{
    axios.defaults.baseURL = 'https://api.forms.ao-nk.ru'
    axios.defaults.backUrl = 'https://forms.ao-nk.ru'
  }

var auth = createAuth({
  plugins: {
    http: axios,
    router: router
  },
  drivers: {
    http: driverHttpAxios,
    auth: driverAuthBearer,
    router: driverRouterVueRouter,
    oauth2: {
      aonkId: aonkId,
    }
  },
  options: {
    authRedirect: '/login',
  }
})

export function registerPlugins (app) {
  loadFonts()
  app
    .use(vuetify)
    .use(router)
    .use(VueAxios, axios)
    .use(pinia)
    .use(auth)


    app.config.globalProperties.$auth = auth;

    if (process.env.NODE_ENV === 'development') {
        app.config.globalProperties.$storagePath = "http://localhost:8365/public/";
        app.config.globalProperties.$wsUrl = "ws://localhost:8366";
      }else{
        app.config.globalProperties.$storagePath = "https://api.forms.ao-nk.ru/public/";
        app.config.globalProperties.$wsUrl = "wss://api.forms.ao-nk.ru";
      }

    pinia.$auth = auth;
}


// использвать сокет во vue